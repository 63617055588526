<template>
    <!-- begin:: Aside -->
    <aside class="page-sidebar">
        <!-- BEGIN PRIMARY NAVIGATION -->
        <div class="page-logo">
            <router-link :to="{ name : 'dashboard' }" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
                <img :src="publicPath + 'media/birthday/logo_birthday_2024.png'" style="width: 89px;" alt="SmartAdmin WebApp" aria-roledescription="logo" class="ml-5">
                <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            </router-link>
<!--            <span class="close-menu" @click="hideMenuMenu"><i class="fa fa-times"></i></span>-->
        </div>
        <edutalk-menu></edutalk-menu>
        <!-- END PRIMARY NAVIGATION -->
        <!-- NAV FOOTER -->
        <div class="nav-footer shadow-top">
            <a href="#" onclick="return false;" data-action="toggle" data-class="nav-function-minify" class="hidden-md-down">
                <i class="ni ni-chevron-right"></i>
                <i class="ni ni-chevron-right"></i>
            </a>
            <ul class="list-table m-auto nav-footer-buttons">
                <li>
                    <a href="https://hotro.edutalk.edu.vn" target="_blank" data-toggle="tooltip" data-placement="top" title="" data-original-title="Support Chat">
                        <i class="fal fa-life-ring"></i>
                    </a>
                </li>
                <li>
                    <a href="tel:19004788" data-toggle="tooltip" data-placement="top" title="" data-original-title="Make a call">
                        <i class="fal fa-phone"></i>
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
    import { mapGetters } from "vuex";
    import EdutalkMenu from "@/views/layout/aside/edutalk-menu.vue";

    export default {
        name: "EdutalkAside",
        data() {
            return {
                publicPath: process.env.VUE_APP_BASE_URL,
                insideTm: 0,
                outsideTm: 0,
            };
        },
        components: {
            EdutalkMenu
            // KTBrand,
            // KTMenu,
        },
        mounted() {
            this.$nextTick(() => {
            });
        },
        methods: {
            /**
             * Use for fixed left aside menu, to show menu on mouseenter event.
             */

            hideMenuMenu() {
                if (document.body.classList.contains("mobile-nav-on")) {
                    document.body.classList.remove("mobile-nav-on");
                }
            },

            mouseEnter() {
                if (this.layoutConfig("aside.self.minimize.hoverable")) {
                    // check if the left aside menu is fixed
                    if (document.body.classList.contains("aside-fixed")) {
                        if (this.outsideTm) {
                            clearTimeout(this.outsideTm);
                            this.outsideTm = null;
                        }

                        // if the left aside menu is minimized
                        if (document.body.classList.contains("aside-minimize")) {
                            document.body.classList.add("aside-minimize-hover");

                            // show the left aside menu
                            document.body.classList.remove("aside-minimize");
                        }
                    }
                }
            },

            /**
             * Use for fixed left aside menu, to show menu on mouseenter event.
             */
            mouseLeave() {
                if (this.layoutConfig("aside.self.minimize.hoverable")) {
                    if (document.body.classList.contains("aside-fixed")) {
                        if (this.insideTm) {
                            clearTimeout(this.insideTm);
                            this.insideTm = null;
                        }

                        if (document.querySelector(".aside-menu .scroll")) {
                            document.querySelector(".aside-menu .scroll").scrollTop = 0;
                        }

                        // if the left aside menu is expand
                        if (document.body.classList.contains("aside-minimize-hover")) {
                            // hide back the left aside menu
                            document.body.classList.remove("aside-minimize-hover");
                            document.body.classList.add("aside-minimize");
                        }
                    }
                }
            },
        },
        computed: {
            ...mapGetters(["layoutConfig", "getClasses"]),

            /**
             * Get extra classes for menu based on the options
             */
            asideMenuClass() {
                const classes = this.getClasses("aside_menu");
                if (typeof classes !== "undefined") {
                    return classes.join(" ");
                }
                return null;
            },
        },
    };
</script>

<style>
.page-logo {
        background-color:unset;
        background-size: cover !important;
}
.page-sidebar {
    background-image: url("/media/birthday/banner_mobi_2024.png");
    background-size: cover !important;
}
</style>
