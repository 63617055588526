<template>
  <!-- BEGIN Page Header -->
  <header class="page-header bg-image" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo ml-4">
      <router-link :to="{name: 'dashboard'}" v-slot="{ href, navigate}">
        <a :href="href" @click="navigate"
           class="page-logo-link press-scale-down d-flex align-items-center position-relative"
           data-toggle="modal" data-target="#modal-shortcut">
          <img :src="publicPath + 'media/birthday/logo_birthday_2024.png'" class="logo-edutalk ml-6" alt="SmartAdmin WebApp"
               aria-roledescription="logo">
          <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
        </a>
      </router-link>
    </div>
    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-md-down dropdown-icon-menu position-relative">
      <a href="#" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
         title="Hide Navigation">
        <i class="ni ni-menu"></i>
      </a>
      <ul>
        <li>
          <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-minify"
             title="Minify Navigation">
            <i class="ni ni-minify-nav"></i>
          </a>
        </li>
        <li>
          <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed"
             title="Lock Navigation">
            <i class="ni ni-lock-nav"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
      <a href="#" class="header-btn btn press-scale-down" @click="openMenuMobile" data-action="toggle"
         data-class="mobile-nav-on">
        <i class="ni ni-menu"></i>
      </a>
    </div>
    <div class="search">

    </div>
    <div class="ml-auto d-flex">
      <div v-bind:class="{ show : menuNotification }" @blur="menuNotification = false">
        <a href="#" class="header-icon" @click="toggleMenuNotification(menuNotification)" data-toggle="dropdown"
           title="You got 12 notifications" :aria-expanded="menuNotification">
          <i class="fal fa-bell"></i>
          <span class="badge badge-icon" v-if="notification_new > 0">{{ notification_new }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-animated dropdown-xl">
          <div
              class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top mb-2">
            <h4 class="m-0 text-center color-white">
              {{ notification_new }} thông báo mới
              <small class="mb-0 opacity-80">Thông báo của bạn</small>
            </h4>
          </div>
          <div class="tab-content tab-notification"
          >
            <div class="tab-pane" v-bind:class="{ active: tabFeeds}" id="tab-feeds" role="tabpanel" v-loading="loading">
              <div class="custom-scroll overflow-auto h-100" id="notification-container">
                <ul class="notification">
                  <li class="unread cursor-pointer" v-for="(item,index) in notifications.data" :key="index"
                      @click="tickRead(item)"
                      v-bind:class="{ isRead:item.is_readed}">

                    <div class="d-flex align-items-center show-child-on-hover">
                                                            <span class="d-flex flex-column flex-1">
                                                                <span
                                                                    class="name d-flex align-items-center">{{
                                                                    item.title
                                                                  }}
                                                                </span>
                                                                <span class="msg-a fs-sm">
                                                                   {{ item.body }}
                                                                </span>
                                                                <span
                                                                    class="fs-nano text-muted mt-1">
                                                                  {{ item.created_at | agoDate }}</span>
                                                            </span>
                      <div class="show-on-hover-parent position-absolute pos-right pos-bottom p-3">
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-pane" v-bind:class="{ active: tabCalendar}" id="tab-events" role="tabpanel">
              <div class="d-flex flex-column h-100">
                <edutalk-calendar></edutalk-calendar>
                <div class="flex-1 custom-scroll">
                  <div class="p-2">
                    <div class="d-flex align-items-center text-left mb-3">
                      <div class="width-5 fw-300 text-primary l-h-n mr-1 align-self-start fs-xxl">
                        15
                      </div>
                      <div class="flex-1">
                        <div class="d-flex flex-column">
                                                                    <span class="l-h-n fs-md fw-500 opacity-70">
                                                                        October 2020
                                                                    </span>
                          <span class="l-h-n fs-nano fw-400 text-secondary">
                                                                        Friday
                                                                    </span>
                        </div>
                        <div class="mt-3">
                          <p>
                            <strong>2:30PM</strong> - Doctor's appointment
                          </p>
                          <p>
                            <strong>3:30PM</strong> - Report overview
                          </p>
                          <p>
                            <strong>4:30PM</strong> - Meeting with Donnah V.
                          </p>
                          <p>
                            <strong>5:30PM</strong> - Late Lunch
                          </p>
                          <p>
                            <strong>6:30PM</strong> - Report Compression
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              class="py-2 px-3 bg-faded d-block rounded-bottom border-faded border-bottom-0 border-right-0 border-left-0">
            <span class="read-all fs-xs fw-500 ml-auto" @click="tickReadAll()">
              Đánh dấu đã đọc
            </span>
            <router-link class="fs-xs fw-500 ml-auto float-right" :to="{ name: 'notification-all' }">
              Xem tất cả thông báo
            </router-link>
          </div>
        </div>
      </div>
      <!-- app user menu -->
      <div v-bind:class="{ show : menuProfile }" @blur="menuProfile = false">
        <a href="#" @click.prevent="toggleMenuProfile(menuProfile)" data-toggle="dropdown"
           :title="currentUser.email"
           :aria-expanded="menuProfile"
           class="header-icon d-flex align-items-center justify-content-center ml-2">
          <img :src="currentUser.image ? currentUser.image : publicPath + 'media/default-avatar.png'"
               class="profile-image rounded-circle"
               alt="">
        </a>
        <div class="dropdown-menu dropdown-menu-animated dropdown-lg show" x-placement="bottom-start"
             style="position: absolute; top: 66px; left: 1627px; will-change: top, left;">
          <div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
            <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                                            <span class="mr-2">
                                                <img
                                                    :src="currentUser.image ? currentUser.image : publicPath + 'media/default-avatar.png'"
                                                    class="rounded-circle profile-image" alt="">
                                            </span>
              <div class="info-card-text">
                <div class="fs-lg text-truncate text-truncate-lg">{{ currentUser.name }}</div>
                <span class="text-truncate text-truncate-md opacity-80">{{ currentUser.email }}</span>
              </div>
            </div>
          </div>
          <div class="dropdown-divider m-0"></div>
          <router-link
              to="/profile"
              v-slot="{ href, navigate }"
          >
            <a :href="href" class="dropdown-item" data-action="app-reset" @click="navigate">
              <span data-i18n="drpdwn.reset_layout">Thông tin cá nhân</span>
            </a>
          </router-link>

          <div class="dropdown-divider m-0"></div>
          <a class="dropdown-item fw-500 pt-3 pb-3" @click="logOut">
            <span data-i18n="drpdwn.page-logout">Đăng xuất</span>
          </a>
        </div>
      </div>
    </div>
  </header>
  <!-- END Page Header -->
</template>

<script>
import {mapGetters} from "vuex";
import EdutalkCalendar from "@/views/content/edutalk-calendar.vue";
import {LOGOUT} from "@/core/services/store/auth.module";
import {GET_NOTIFICATION} from "@/core/services/store/notification.module";
import {PUSH_DATA_LAST, SET_PAGE, UPDATE_STATUS_READED,UPDATE_STATUS_READED_ALL,RESET_PAGE} from "../../../core/services/store/notification.module";

export default {
  name: "EdutalkHeader",
  components: {
    EdutalkCalendar
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      menuProfile: false,
      menuNotification: false,
      menuShort: false,
      tabFeeds: true,
      tabCalendar: false,
      loading: false,
      page:1,
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser", "notifications", "notification_new", "links"]),
  },
  created() {
    document.addEventListener('click', this.closePopupHeader);
    // let externalScript = document.createElement('script')
    // externalScript.setAttribute('src', 'https://www.example-of-external-script.com/script.js')
    // document.head.appendChild(externalScript)
  },
  mounted() {
    this.getNotifications();
    this.eventScroller();
    this.excitedBroadcasting()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopupHeader)
  },
  methods: {
    closePopupHeader(e) {
      if (!this.$el.contains(e.target)) {
        this.menuProfile = false;
        this.menuNotification = false;
        this.menuShort = false;
      }
    },
    excitedBroadcasting() {
      this.$echo.channel('information').listen('InformationCreated', (payload) => {
        if (this.checkPushNotification(payload)) {
          this.$store.dispatch(PUSH_DATA_LAST, payload).then(() => {
            // this.socket.unsubscribe('information');
          });
        }
      });
    },
    checkPushNotification(payload) {
      let information = payload.information;
      let accountType = this.currentUser.account_type;
      let userPosition = this.currentUser.user_positions;
      let profile = this.currentUser.profile;
      let businessLevel = this.currentUser.profile.business_level_id
      let areaUser = this.currentUser.area_id
      let notification_storage = localStorage.getItem('notification');
      if (notification_storage === information.id) {
        return false;
      } else {
        localStorage.setItem('notification', information.id);
      }

      if (information.is_global === 1) {
        return true;
      }
      if (information.user_id === this.currentUser.id) {
        return true;
      }

      // Check loại tài khoản
      if (information.account_type) {
        let convertToArray = information.account_type.split(",");
        let check = convertToArray.indexOf(accountType.id.toString());
        let checkAccountType = false
        let checkBusinessLevel = true
        let checkArea = true
        if (information.type === 6) { // Case : nhan su cho
          if (check !== -1) {
            // Nếu user cùng chi nhánh or là Hành chính nhân sự sẽ được xem notification
            if (profile.account_type_id === 18) {
              checkAccountType = true;
            }
          }
        } else {
          if (check !== -1) {
            checkAccountType = true;
          }
        }
        if (information.business_level) {
          let convertBusinessLevelToArray = information.business_level.split(",");
          let checkBusinessLevelExist = convertBusinessLevelToArray.indexOf(businessLevel.toString());
          if (checkBusinessLevelExist !== -1) {
            checkBusinessLevel = true
          } else {
            checkBusinessLevel = false
          }
        }
        if (information.areas) {
          let convertAreasToArray = information.areas.split(",");
          let checkAreasExist = convertAreasToArray.indexOf(areaUser.toString());
          if (checkAreasExist !== -1) {
            checkArea = true
          } else {
            checkArea = false
          }
        }
        if (checkAccountType == false || checkBusinessLevel == false || checkArea == false) {
          return false
        } else {
          return true
        }
      }
      // check chức vụ kiêm nhiệm
      if (userPosition.length) {
        let userPosition = this.currentUser.user_positions;
        let positionNotify = information.positions;
        for (let i = 0; i < userPosition.length; i++) {
          if (positionNotify != '' && positionNotify.includes(userPosition[i].position)) {
            return true;
          }
          if (information.type == 6 && userPosition[i].position === 'TPNS' && profile.chi_nhanh_id === information.branch_id) {
            return true;
          }

        }
      }
      return false;
    },
    async eventScroller() {
      const scroller = document.querySelector("#notification-container");
      scroller.addEventListener("scroll", () => {
        if (scroller.scrollHeight === scroller.scrollTop + scroller.clientHeight) {
          this.setPage();
        }
      });
    },
    setPage() {
      this.page = this.page + 1;
      this.scrollNotification();
    },
    resetShowMenu() {
      this.menuProfile = false;
      this.menuNotification = false;
      this.menuShort = false;
    },
    toggleMenuProfile(menuProfile) {
      this.resetShowMenu();
      this.menuProfile = !menuProfile
    },
    toggleMenuNotification(menuNotification) {
      this.resetShowMenu();
      this.menuNotification = !menuNotification
    },
    toggleMenuShort(menuShort) {
      this.resetShowMenu();
      this.menuShort = !menuShort
    },
    activeTab(tab) {
      if (tab === 'tabCalendar') {
        this.tabCalendar = true;
        this.tabFeeds = false;
      } else {
        this.tabCalendar = false;
        this.tabFeeds = true;
      }
    },
    logOut() {
      this.$store.dispatch(LOGOUT).then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('_token');
        localStorage.removeItem('event');
        this.$router.push({name: 'login'})
      });
    },
    getNotifications() {
      this.loading = true;
      setTimeout(() => {
          this.$store.dispatch(GET_NOTIFICATION, {
            limit: this.limit,
            page: this.page,
          }).then(() => {
            this.loading = false;
          });
      }, 3000)

    },
    getNotificationOne() {
      this.loading = true;
      this.$store.dispatch(GET_NOTIFICATION, {
        limit: this.limit,
        page: 1,
      }).then(() => {
        this.loading = false;
      });
    },
    scrollNotification() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(GET_NOTIFICATION, {
          page: this.page,
          limit: 20,
        }).then(() => {
          this.loading = false;
        });
      }
    },
    tickRead(item) {
      const id = item.id;
      if (item.is_readed) {
        this.redirectLink(item);
        return;
      }
      this.$store.dispatch(UPDATE_STATUS_READED, {
        id: id,
      }).then((response) => {
        if (!response.error) {
          this.notifications.data.filter((value) => {
            if (value.id == id) {
              return value.is_readed = response.data.is_readed
            }
          })
        }
        this.redirectLink(item);
      });
    },
    tickReadAll(){
      this.loading = true;
        this.$store.dispatch(UPDATE_STATUS_READED_ALL).then((response) => {
            if (!response.error) {
              this.$store.dispatch(RESET_PAGE).then((response) => {
                });
            this.getNotificationOne();
            }
          });
      this.loading = false;
    },
    redirectLink(item) {
      if (item.flag_old) {
        // Case : cập nhật phiếu lương bên educenter.
        if (item.type == 1) {
          window.location = this.links[item.type];
        } else {
          if (item.url) window.location = item.url;
        }
      } else window.location = this.links[item.type];
    },
    openMenuMobile() {
      document.body.classList.add("mobile-nav-on");
      let backdrop = document.querySelector(".hideBackDrop");
      if (backdrop.classList.contains("hide")) {
        backdrop.classList.remove('hide');
        backdrop.classList.add('add');
      }
    },
  },
};
</script>
<style>
.isRead {
  background: #ffffff !important;
}
.read-all {
  touch-action: manipulation;
  color: #886ab5;
  cursor: pointer;
}
.read-all:hover {
  opacity: 0.9;
}
.bg-image {
    background-image: url("/media/birthday/banner_header_birthday_2024.png");
    background-size: cover !important;
}
</style>
